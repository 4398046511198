'use client'
import { useState } from 'react'
import dynamic from 'next/dynamic'
import { classNames } from '@/lib/utils'
const ActualVideoModal = dynamic(() => import('./ActualVideoModal').then(mod => mod.default), { ssr: false })

interface ModalVideoProps {
    children?: React.ReactNode
    videoChildren?: React.ReactNode
    className?: string
}

export default function ModalVideo({
    children,
    videoChildren,
    className,
}: ModalVideoProps) {
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    return (
        <div className={classNames("flex justify-center", className)}>

            {/* Video thumbnail */}
            <button className="relative flex items-center justify-center focus:outline-none focus-visible:ring focus-visible:ring-indigo-300 rounded-3xl group" onClick={() => { setModalOpen(true) }} aria-label="Watch the video">
                {children}
            </button>
            {/* End: Video thumbnail */}

            <ActualVideoModal show={modalOpen} onClose={() => { setModalOpen(false) }}>
                {videoChildren}
            </ActualVideoModal>
        </div>
    )
}