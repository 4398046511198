'use client';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import clsx from 'clsx';
import { useScopedI18n } from 'locales/client';
import Anchor from 'shared-ui/components/Anchor';
import MainCTAButton from './MainCTAButton';
const Confetti = dynamic(() => import('react-dom-confetti'));
import InstagramProfileCard from '@/components/presentational/tailwind/InstagramProfileCard';
import { HiOutlinePlay } from 'react-icons/hi';
import { RiHeart3Fill } from 'react-icons/ri';
import { FaUserTag } from 'react-icons/fa';

import VideoThumb from '@public/img/graphics/upgrow-video-thumbnail.webp';
import ModalVideo from '@/components/landing/ModalVideo';

// import { BsInstagram } from 'react-icons/bs';
// import InstagramHeroIcon from '@public/img/icons/instagram-hero.svg';

export default function LandingHero() {
  const t = (useScopedI18n as any)("landing.hero");

  const [showConfetti, setShowConfetti] = useState(false);
  const onLoadedProfile = (instagramProfile) => {
    console.log(instagramProfile);
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  };

  return (
    <div className="bg-white isolate">
      <div className="absolute inset-x-0 top-[-10rem] transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" role='presentation' style={{ zIndex: -10, pointerEvents: 'none' }}>
        <svg className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]" viewBox="0 0 1155 678" >
          <path fill="url(#9b2541ea-d39d-499b-bd42-aeea3e93f5ff)" fillOpacity=".35" d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
          <defs>
            <linearGradient
              id="9b2541ea-d39d-499b-bd42-aeea3e93f5ff"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <main>
        <div className={clsx(`hero-gradient before:-scale-2 relative overflow-hidden pt-8 sm:pt-16 pb-0 before:absolute`,
          "before:top-1/2 before:left-1/2 before:-z-[1] before:h-96 before:w-full before:-translate-y-1/2 before:-translate-x-1/2 before:transform")}
        >

          <Image src="/img/landing-hero-overlay.svg" alt="hero overlay pattern" width={1728} height={949} className="absolute inset-0 z-[-9] object-cover object-center h-96 my-auto" loading="eager" />
          <svg height="0" width="0"><linearGradient id="ig-gradient" x1="0%" x2="100%" y1="0%" y2="100%"><stop offset="0%" stopColor="#4b25ea">{/* <animate attributeName="stop-color" dur="4s" repeatCount="indefinite" values="#4b25ea; #bd18e6; #fb590e"/>*/}</stop><stop offset="30%" stopColor="#bd18e6">{/* <animate attributeName="stop-color" dur="4s" repeatCount="indefinite" values="#4b25ea; #bd18e6; #fb590e"/>*/}</stop><stop offset="100%" stopColor="#fb590e">{/* <animate attributeName="stop-color" dur="4s" repeatCount="indefinite" values="#4b25ea; #bd18e6; #fb590e"/>*/}</stop></linearGradient></svg>

          <div className="px-6 mx-auto max-w-7xl lg:px-8">
            <div className="max-w-5xl mx-auto text-center">

              <h1 className="group hero-h1-gradient text-left sm:text-center whitespace-pre-wrap bg-clip-text text-transparent text-5xl font-bold tracking-[-1.5px] sm:tracking-[-2px] text-gray-900 text-[34px] sm:text-[45px] md:text-[50px] lg:text-[61px] lg:h-[135px] leading-[39px] sm:leading-[65px] ">
                <span className='mr-2 sm:mr-0'>Get Real{" "}</span>
                <span className={clsx('bg-[length:170%] whitespace-nowrap',
                  'inline-block bg-clip-text text-transparent saturate-[1.2]')}>
                  <Image src="/img/graphics/instagram-logo.svg" loading="eager" title="Logo of Instagram" alt="Logo of Instagram" width={50} height={50} priority={true}
                    className={clsx('inline-flex relative my-auto transition-all animate-gradient bg-[length:120%] pointer-events-none hero-text-gradient group-hover:-rotate-12 group-hover:scale-95',
                      'sm:rounded-[0.8rem] sm:w-[3.2rem] sm:h-[3.2rem] sm:mt-[-16px] sm:mr-[11px] sm:ml-[4px] sm:p-[7px]',
                      'rounded-[10px] w-[2rem] h-[2rem] ml-0 mr-[6px] mt-[-6px] p-[5px]')}
                    style={{
                      backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', display: 'inline-flex', position: 'relative', boxShadow: '0 8px 8px rgba(23,22,24,.04), 0 4px 4px rgba(23,22,24,.04), 0 2px 2px rgba(23,22,24,.04)',
                    }} />

                  <span className='text-transparent hero-text-gradient bg-clip-text bg-[length:120%] saturate-[0.85]'>
                    Instagram
                    <span className="inline md:hidden">{" "}Followers</span>
                  </span>

                  {/* Sliding text – hidden from sm & up */}
                  <span data-nosnippet className="relative ml-3 h-[0.86em] overflow-hidden hidden md:inline-flex text-left w-[257px] saturate-[0.85]">
                    {['Followers', 'Likes', 'Fans'].map((text, index) => (
                      <span key={index}
                        className="hero-text-gradient-reversed bg-[length:170%] whitespace-nowrap bg-clip-text text-transparent absolute h-full w-full -translate-y-full animate-slide leading-none"
                        style={{ animationDelay: `${(3.5 / 3) * index}s` }} // 3.5s is the slide animation duration
                      >
                        {text}
                        {index === 1 && <RiHeart3Fill className='inline-block w-10 h-10 ml-2.5 align-middle' style={{ fill: 'url(#ig-gradient)', transform: 'scaleX(-1) translateY(-4px)' }} />}
                        {index === 2 && <FaUserTag className='inline-block w-10 h-10 ml-2.5 align-middle' style={{ fill: 'url(#ig-gradient)', transform: 'scaleX(-1) translateY(-3px)' }} />}
                      </span>
                    ))}
                  </span>

                  {" "}
                </span>
                {/* <span className="relative inline-flex align-baseline">
                    <BsInstagram className="my-auto ml-2 mr-6 font-bold h-14 w-14" style={{ fill: 'url(#ig-gradient)' }} />
                  </span> */}
                <br />
                {/* <span className={clsx('',
                  'inline-block bg-clip-text text-transparent saturate-[1.2]'
                )}> */}
                automatically using
                <span className='inline sm:hidden'> </span>
                <Image src="/img/graphics/open-ai-logo.svg" loading="eager" title="Logo of Open AI" alt="Logo of Open AI" width={50} height={50}
                  className='hidden sm:inline-flex w-[2.5rem] h-[2.5rem] sm:w-[3.2rem] sm:h-[3.2rem] sm:mt-[-11px] sm:mr-[9px] sm:ml-[14px] p-1.5 my-auto hero-text-gradient pointer-events-none group-hover:-rotate-12 group-hover:scale-95 transition-all duration-300 ease-in-out saturate-[1.2]'
                  style={{
                    backgroundColor: 'transparent', borderRadius: '0.8rem', justifyContent: 'center', alignItems: 'center', position: 'relative', boxShadow: '0 8px 8px rgba(23,22,24,.04), 0 4px 4px rgba(23,22,24,.04), 0 2px 2px rgba(23,22,24,.04)',
                  }} />
                AI.
                {/* </span> */}
              </h1>


              {/* Old Version */}
              {/* <h1 className={clsx('hidden hero-h1-gradient whitespace-pre-wrap md:whitespace-pre bg-clip-text text-3xl text-left sm:text-center font-bold tracking-tight  text-gray-900 text-transparent',
                'sm:text-5xl',
                'xl:tracking-[-2.7px] xl:text-[70px] xl:h-[145px] xl:leading-[72px]',
                'md:leading-[65px] md:h-[130px]',
                'lg:h-[110px] lg:leading-[54px]')}>
                {t('title', { break: <br className="block sm:hidden" /> })}
                <br className='hidden md:block' />{" "}
                <span className="relative hidden align-baseline md:inline-flex">
                  <div className='relative'>
                    <BsInstagram className="my-auto ml-2 mr-6 font-bold md:h-11 md:w-11 xl:h-14 xl:w-14" style={{ fill: 'url(#ig-gradient)' }} />
                    <BsInstagram className="absolute top-0 bottom-0 left-0 right-0 my-auto ml-2 mr-6 font-bold scale-105 md:h-11 md:w-11 xl:h-14 xl:w-14" style={{ fill: 'url(#ig-gradient)' }} />
                  </div>
                </span>
                <span className={clsx('hero-text-gradient animate-gradient bg-[length:170%] pointer-events-none ',
                  'inline md:inline-block bg-clip-text text-transparent saturate-[1.2]')} style={{ height: 'inherit' }}>
                  {t('title2')}
                </span>
              </h1> */}

              <p className="mt-4 mb-2 sm:mb-0 sm:mt-6 sm:text-[21px] text-xl font-medium sm:leading-[32px] leading-snug text-gray-600 text-left sm:text-center">
                {t('description1')}
                <br className='hidden sm:block' />{' '}
                {t('description2')}
                <br className='hidden sm:block' />{' '}
                <span className="bg-[linear-gradient(90deg,transparent_24%,#ffdc59)] py-1 pl-0 pr-2 leading-normal sm:leading-[41px] saturate-[1.2] cursor-grab transition-all duration-300 ease-in-out hover:bg-[linear-gradient(90deg,transparent_-20%,#ffdc59)] hover:text-black hover:saturate-[2]">
                  {/* <br className='hidden md:block' /> */}
                  {t('description3', {
                    break: (
                      <>
                        <span className="hidden sm:inline"> –</span>
                        <span className="inline sm:hidden">,</span>
                      </>
                    )
                  })}

                </span>
              </p>
              <div className="items-center justify-center inline-block w-full mt-6 sm:mt-10 gap-x-6 sm:flex">
                <MainCTAButton className='w-full mb-4 sm:w-auto sm:mb-0' />

                <Anchor className="justify-center hidden w-full px-6 py-3 text-lg font-medium transition-all duration-200 bg-white rounded-lg sm:inline-flex sm:w-auto hover:ring-slate-900/15 text-slate-500 ring-1 ring-slate-900/10 hover:scale-95 hover:bg-gray-50"
                  style={{ boxShadow: '0px 1px 3px rgba(0,0,0,.06), 0px 0px 0px 1px rgba(106,115,133,.16)' }}
                  href="https://demo.upgrow.com/demo" target="_blank" prefetch={false}
                >
                  <span>
                    <span className="flex items-center">
                      <HiOutlinePlay className="w-5 h-5 mr-2 text-slate-400" />
                      {t('ctaDemo')}
                      {/* <TbExternalLink className="ml-1.5 h-5 w-5 text-slate-400" /> */}
                    </span>
                  </span>
                </Anchor>
              </div>

              {/* Checkmark list */}
              {/* FIXME: Optimize this to not repeat the svgs */}
              <div className="flex flex-col flex-1 md:mx-auto">
                <ul className="flex-wrap justify-center block mx-auto mt-5 mb-2 sm:mt-8 md:flex lg:mx-auto lg:justify-start">
                  <li className="items-center justify-center hidden w-full mb-2 text-base font-semibold list-none sm:flex md:w-auto md:justify-start">
                    <div className="mr-3">
                      <svg width={15} height={11} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.46998 6.69698L5.46519 10.0354C5.46519 10.0354 6.90169 5.01243 13.7888 1.36218" stroke="#2047F4" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    {t('growthGuaranteed')}
                    {/* Results Since Day 1 */}
                    {/* 1-Click Growth Setup */}
                  </li>
                  <li className="flex items-center justify-center w-full mb-2 text-base font-semibold list-none md:w-auto md:justify-start lg:pl-8">
                    <div className="mr-3">
                      <svg width={15} height={11} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.46998 6.69698L5.46519 10.0354C5.46519 10.0354 6.90169 5.01243 13.7888 1.36218" stroke="#2047F4" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    {t('noCreditCard')}
                  </li>
                  <li className="items-center justify-center hidden w-full mb-2 text-base font-semibold list-none sm:flex md:w-auto md:justify-start lg:pl-8">
                    <div className="mr-3">
                      <svg width={15} height={11} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.46998 6.69698L5.46519 10.0354C5.46519 10.0354 6.90169 5.01243 13.7888 1.36218" stroke="#2047F4" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    {t('support')}
                  </li>
                </ul>
              </div>

              {/* Star rating */}
              <div className="relative mt-4">
                <div className="inline-block">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 pb-0 pr-4 sm:flex">
                      {/* Avatar Group */}
                      <div className="flex justify-center -space-x-3">
                        {["upgrow-customer-1", "upgrow-customer-2", "upgrow-customer-3", "upgrow-customer-4"].map((customer, index) => (
                          <Image
                            key={index}
                            width={32}
                            height={32}
                            className="inline-block w-8 h-8 transition-transform duration-200 ease-in-out rounded-full ring-2 ring-white hover:-translate-y-1"
                            src={`/img/graphics/${customer}.jpeg`}
                            loading="eager"
                            style={{ zIndex: index + 1 }}
                            alt={`UpGrow Customer Avatar ${index + 1}`}
                          />
                        ))}
                        <span className="inline-flex items-center justify-center h-8 bg-gray-800 rounded-full w-9 ring-2 ring-white dark:bg-gray-900 dark:ring-gray-800" style={{ zIndex: 5 }}>
                          <span className="text-xs font-medium leading-none text-white uppercase">
                            58K+
                          </span>
                        </span>
                      </div>
                      {/* End Avatar Group */}
                    </div>
                    <div className="flex flex-col flex-1 text-left">
                      <div className="flex items-center">
                        {/* TODO: Move StartRating and StarIcon into a separate component and reuse them in all landing components */}
                        <StarRating rating={5} />
                        <span className="ml-2 mt-[2px] leading-[1px]">
                          <b>4.98</b>/5
                        </span>
                      </div>
                      <p className="text-sm text-gray-800 whitespace-nowrap">
                        {/* Based on 1000+ reviews */}
                        {t('trusted')}
                      </p>
                    </div>

                    <ModalVideo className='ml-4'
                      videoChildren={
                        <iframe
                          width="100%"
                          height="100%"
                          src={`https://www.youtube.com/embed/drAk4w2BQAY?autoplay=1&playsinline=1&controls=0&rel=0&modestbranding&showinfo=0`}
                          title="UpGrow Demo Video"
                          allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      }
                    >
                      <Image className="transition-all duration-700 ease-in-out rounded-lg shadow-sm opacity-50 hover:shadow-lg hover:opacity-100 transform-gpu animate-sway"
                        src={VideoThumb} width={85} height={47} priority alt="UpGrow Demo Video Thumbnail" quality={60}
                      />
                      {/* Play icon */}
                      <svg className="absolute transition-all ease-in-out scale-75 pointer-events-none group group-hover:scale-90 opacity-80 group-hover:opacity-100" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
                        <circle className="fill-white" cx="24" cy="24" r="20" />
                        <path className="fill-purple-500 drop-shadow-2xl" d="M30 24a.999.999 0 0 0-.427-.82l-10-7A1 1 0 0 0 18 17v14a.999.999 0 0 0 1.573.82l10-7A.995.995 0 0 0 30 24v0Z" />
                      </svg>
                    </ModalVideo>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mx-auto mt-[55px] flex w-auto justify-center">
              <div className="absolute w-1 mx-auto overflow-visible">
                <Confetti
                  active={showConfetti}
                  config={{
                    angle: 90,
                    spread: 70,
                    startVelocity: 45,
                    elementCount: 50,
                    width: '10px',
                    height: '10px',
                    colors: [
                      '#a864fd',
                      '#29cdff',
                      '#78ff44',
                      '#ff718d',
                      '#fdff6a',
                    ],
                    duration: 3e3,
                    stagger: 0,
                    dragFriction: 0.1,
                    random: Math.random,
                  }}
                />
              </div>

              {/* <div className="absolute -right-full top-0 flex flex-row-reverse items-end pt-2 leading-none transition-all duration-300 md:right-[70%] md:flex-row">
                <Image
                  className="transition-all duration-300 ease-in-out cursor-pointer hover:scale-95 hover:!opacity-100"
                  src="/img/graphics/upgrow-thumbnail.jpg"
                  alt="UpGrow Video Thumbnail"
                  width={177}
                  height={126}
                  style={{
                    position: 'relative',
                    bottom: '31%',
                    left: '0',
                    opacity: 0.7,
                    zIndex: 9999999,
                    top: '-20px',
                    // filter: 'hue-rotate(250deg) saturate(6) contrast(0.6) brightness(0.9)',
                    transform: 'rotate(-14deg)',
                    borderRadius: '10px',
                  }}
                />
              </div> */}


              {/* animated arrow gif */}
              <span className={clsx(
                'absolute -left-full top-0 flex flex-row-reverse items-end pt-2 leading-none text-green-500 transition-all duration-300 md:left-[70%] md:flex-row',
                'translate-y-0 opacity-100'
              )}
              >
                {/* <svg className="transform -scale-x-100 md:scale-x-100" width={25} height={25} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="M28.0919 25.8337C28.4923 25.765 28.8906 26.0698 28.9814 26.5144C29.0723 26.9589 28.8213 27.3748 28.4209 27.4435L28.0919 25.8337ZM8.12688 1.00838C8.52826 0.944434 8.92385 1.25354 9.0105 1.69876L10.4225 8.95421C10.5091 9.39944 10.2541 9.81222 9.85268 9.87616C9.4513 9.9401 9.05571 9.631 8.96906 9.18577L7.71397 2.7365L1.90052 3.66282C1.49914 3.72677 1.10355 3.41768 1.0169 2.97245C0.930253 2.52721 1.18533 2.11445 1.58671 2.05051L8.12688 1.00838ZM28.4209 27.4435C18.8087 29.0908 10.9559 27.0111 6.80609 22.1317C2.56855 17.1494 2.50163 9.67105 7.65909 1.32639L8.90862 2.30272C3.93035 10.3575 4.33505 16.9219 7.86047 21.0669C11.4735 25.315 18.6748 27.4474 28.0919 25.8337L28.4209 27.4435Z" fill="currentcolor" /></svg> */}
                {/* <div style={{ width: '36px', height: '40px', backgroundImage: 'url(https://formcarry.com/pointer_light.gif)', backgroundSize: 'cover', border: 'none', position: 'relative', bottom: '31%', left: '0', opacity: 1, zIndex: 9999999, top: '-20px', filter: 'hue-rotate(250deg) saturate(6) contrast(0.6) brightness(0.9)', transform: 'rotate(44deg)' }} /> */}

                <Image
                  src="/img/icons/pointer_light_min.gif"
                  alt="arrow animated icon"
                  loading='lazy'
                  width={36}
                  height={40}
                  style={{
                    position: 'relative',
                    bottom: '31%',
                    left: '0',
                    opacity: 1,
                    zIndex: 9999999,
                    top: '-20px',
                    filter: 'hue-rotate(250deg) saturate(6) contrast(0.6) brightness(0.9)',
                    transform: 'rotate(44deg)',
                  }}
                />
                <span className="text-md relative left-[22px] top-[-22px] whitespace-nowrap font-medium italic">
                  Get your growth{" "}
                  <br />estimate instantly
                </span>
              </span>

              <div className="-m-2 mb-0 max-h-[550px] overflow-hidden !rounded-bl-none !rounded-br-none bg-indigo-600/5 p-2 pb-0 ring-1 ring-inset ring-gray-900/10 lg:-m-4 rounded-[24px] lg:p-4">
                <InstagramProfileCard className="-mb-[8px] rounded-[20px] shadow-2xl ring-1 ring-gray-900/10 md:block flex" onLoadedProfile={onLoadedProfile} />
                {/* <DynamicIGProfileCard
                  className="-mb-[8px] rounded-[20px] shadow-2xl ring-1 ring-gray-900/10"
                  // className='right-[150px] absolute'
                  onLoadedProfile={onLoadedProfile}
                /> */}
              </div>
            </div>
          </div>

          <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <svg className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]" viewBox="0 0 1155 678">
              <path fill="url(#b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1)" fillOpacity=".3" d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
              <defs>
                <linearGradient
                  id="b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </main>
    </div>
  );
}

export function StarRating({ rating }) {
  return (
    <div className="flex">
      {Array.from(Array(5).keys()).map((index) => (
        <StarIcon
          key={index}
          className={clsx('h-5 w-5',
            rating > index ? 'fill-yellow-300' : 'fill-gray-300'
          )}
        />
      ))}
    </div>
  );
}

function StarIcon(props) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  );
}
