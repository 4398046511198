'use client';
import { Fragment, useEffect, useState } from 'react';
import { hasCookie } from 'cookies-next';
import clsx from 'clsx';
import { useScopedI18n } from 'locales/client';
import Anchor from 'shared-ui/components/Anchor';
import { FaInstagram } from 'react-icons/fa';

export default function MainCTAButton({ className }: { className?: string }) {
  const t = (useScopedI18n as any)("landing.hero");

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const cookies = Object.fromEntries(document.cookie.split('; ').map(cookie => cookie.split('=')));
    const supabaseAuthCookie = Object.keys(cookies).find(name => /^sb-.+-auth-token$/.test(name));
    setIsLoggedIn(hasCookie('supabase-auth-token') || hasCookie(supabaseAuthCookie));
  }, []);

  return (
    <Anchor
      href={isLoggedIn ? '/app' : '/setup'}
      className={clsx(className,
        'glow-button group inline-block shadow-md transition-transform duration-200 hover:scale-[.98] h-[54px]',
      )}
    >
      <div className="gradient h-[54px]" />
      <span className="inline-flex bg-indigo-600 px-8 py-3 text-lg font-medium leading-7 text-white transition-all duration-200 h-[54px]">
        <div className="inline-flex items-center align-middle place-content-center min-w-[170px] font-bold" style={{ textShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 2px' }}>
          {isLoggedIn ? (
            <Fragment>
              {t('ctaDashboard')}
              <svg className="my-auto ml-3 -mr-1 stroke-2 stroke-white" fill="none" width="12" height="12" viewBox="0 0 10 10" aria-hidden="true">
                <path className="transition opacity-0 group-hover:opacity-100" d="M0 5h7"></path><path className="transition group-hover:translate-x-[3px]" d="M1 1l4 4-4 4"></path>
              </svg>
            </Fragment>
          )
            : (
              <Fragment>
                <FaInstagram className="mr-3 drop-shadow-lg" style={{ width: '1.35rem', height: '1.35rem', marginLeft: '-8px', marginRight: '12px' }} />
                {t('ctaMain')}
                <svg className="my-auto stroke-2 stroke-white drop-shadow-lg opacity-80 group-hover:opacity-100" style={{ marginTop: '9px', marginLeft: '18px', marginRight: '-15px', height: '0.75rem', width: '0.75rem' }} fill="none" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
                  <path className="transition opacity-0 group-hover:opacity-100" d="M0 5h7"></path><path className="transition group-hover:translate-x-[3px]" d="M1 1l4 4-4 4"></path>
                </svg>
              </Fragment>
            )}
        </div>
      </span>

      {/* Spots left */}
      <div className="flex-col items-center justify-center block mt-6 space-y-2 sm:hidden">
        <div className="flex flex-row items-center justify-center space-x-2">
          <div className="w-2 h-2 bg-black rounded-full" />
          <div className="w-2 h-2 bg-black rounded-full" />
          <div className="w-2 h-2 rounded-full" style={{ background: "rgba(0, 0, 0, 0.2)" }} />
          <div className="w-2 h-2 rounded-full" style={{ background: "rgba(0, 0, 0, 0.2)" }} />
          <div className="w-2 h-2 rounded-full" style={{ background: "rgba(0, 0, 0, 0.2)" }} />
          <div className="w-2 h-2 rounded-full" style={{ background: "rgba(0, 0, 0, 0.2)" }} />
        </div>
        <span className="text-xs font-semibold text-slate-600 animate-pulse" style={{ background: "transparent" }}> 2 SPOTS LEFT</span>
      </div>

    </Anchor>
  );
}


